/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import Switch from 'rc-switch';
import IntlMessages from 'helpers/IntlMessages';
import { CATEGORY_NAME, PATH, PERMISSIONS } from 'constants/common';
import * as XLSX from 'xlsx';
import { isEmpty } from 'lodash/fp';
import { defaultDirection } from '../constants/defaultValues';

export const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const sanitizeEmail = email => {
  if (isEmpty(email)) return '';

  return email
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim();
};

export const handleImportXlsxFile = async (event, callback) => {
  const file = event.target.files[0];

  if (!file) return;
  const reader = new FileReader();
  reader.onload = async e => {
    const data = e.target.result;
    const workbook = XLSX.read(data, { type: 'binary' });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    callback(json);
  };
  reader.readAsBinaryString(file);
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem('direction')) {
    const localValue = localStorage.getItem('direction');
    if (localValue === 'rtl' || localValue === 'ltr') {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};

export const setDirection = localValue => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  localStorage.setItem('direction', direction);
};

export const objectToURLParams = parameters =>
  `?${Object.keys(parameters)
    .map(key => {
      const queryValue = encodeURIComponent(
        Array.isArray(parameters[key])
          ? JSON.stringify(parameters[key])
          : parameters[key]
      );

      return `${encodeURIComponent(key)}=${queryValue}`;
    })
    .join('&')}`;

export const APIParams = ({
  sort = '_id',
  skip = 0,
  limit = null,
  page = 1,
  ...additionalParams
}) => ({
  $sort: `-${sort}`,
  isDeleted: false,
  $skip: skip,
  $limit: limit,
  $page: page,
  ...additionalParams,
});

export const formatId = id => {
  const formattedId = id ? id.slice(-6) : '-';

  return formattedId;
};

export const formatLiters = liters => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });

  const formattedLiters = liters ? `${formatter.format(liters)} L` : '-';

  return formattedLiters;
};

export const formatPrice = price => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const formattedPrice = price ? formatter.format(price) : '-';

  return formattedPrice;
};

export const formatAmount = price => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return formatter.format(price);
};

export const formatFuelCost = fuelCost => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });

  const formattedFuelCost = fuelCost ? `R$${formatter.format(fuelCost)}` : '-';

  return formattedFuelCost.replace('.', ',');
};

export const formatKilometers = km => {
  const formatter = new Intl.NumberFormat('pt-BR');

  const formattedKilometers = km ? `${formatter.format(km)} km` : '-';

  return formattedKilometers;
};

export const formatCPF = cpf => {
  const invalidCPF = !cpf || cpf.length !== 11;
  if (invalidCPF) return cpf;

  return cpf
    .replace(/[^\d]/g, '')
    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export const formatCNPJ = cnpj => {
  const invalidCNPJ = !cnpj || cnpj.length !== 14;
  if (invalidCNPJ) return cnpj;

  return cnpj
    .replace(/[^\d]/g, '')
    .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

export const capitalizeFirstLetter = text => {
  return text.replace(/(^|\s)\S/g, firstLetter => {
    return firstLetter.toUpperCase();
  });
};

export const formatDatetime = datetime => {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  const formattedDatatime = datetime
    ? new Intl.DateTimeFormat('pt-BR', options).format(new Date(datetime))
    : '-';

  return formattedDatatime;
};

export const formatDate = date => {
  const clearRegExp = /(.\S{4})$/g;
  const formatRegExp = /^(\d{4})-(\d{2})-(\d{2})T(\S{8})/g;

  const formattedData = date
    ? date.replace(clearRegExp, '').replace(formatRegExp, '$3/$2/$1')
    : '-';

  return formattedData;
};

export const formatDateAndTime = date => {
  const clearRegExp = /(.\S{4})$/g;
  const formatRegExp = /^(\d{4})-(\d{2})-(\d{2})T(\S{8})/g;

  const formattedData = date
    ? date.replace(clearRegExp, '').replace(formatRegExp, '$3/$2/$1 $4')
    : '-';

  return formattedData;
};

export const formatDateForExport = date => {
  let formattedData = '-';
  if (date) {
    formattedData = new Date(date);
    formattedData = new Date(
      Date.UTC(
        formattedData.getFullYear(),
        formattedData.getMonth(),
        formattedData.getDate(),
        formattedData.getHours(),
        formattedData.getMinutes(),
        formattedData.getSeconds()
      )
    );
  }
  return formattedData;
};

export const formatDateByDayAndMonth = date => {
  const clearRegExp = /(.\S{4})$/g;
  const formatRegExp = /^(\d{4})-(\d{2})-(\d{2})T(\S{8})/g;

  const formattedData = date
    ? date.replace(clearRegExp, '').replace(formatRegExp, '$3/$2')
    : '-';

  return formattedData;
};

export const formatCategory = role => {
  try {
    return CATEGORY_NAME[role];
  } catch {
    return null;
  }
};

export const getSwitch = (organization, onSwitch = () => {}) => {
  const isActive = organization.status === 'active';
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isChecked, setIsChecked] = useState(isActive);
  return (
    <Switch
      id="tooltip_switch"
      className="custom-switch custom-switch-primary custom-switch-small"
      checked={isChecked}
      onChange={() => {
        setIsChecked(!isChecked);
        onSwitch(organization._id, !isChecked);
      }}
    />
  );
};

export const getSwitchData = (data, onSwitch = () => {}) => {
  const isActive = data.status === 'active';
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isChecked, setIsChecked] = useState(isActive);
  return (
    <Switch
      id="tooltip_switch"
      className="custom-switch custom-switch-primary custom-switch-small"
      checked={isChecked}
      onChange={() => {
        setIsChecked(!isChecked);
        onSwitch(data, !isChecked);
      }}
    />
  );
};

export const getStatusLabel = data => {
  const statusProp = data.props ? data.props.status : data.status;
  const isDeletedProp = data.props ? data.props.isDeleted : data.isDeleted;

  let label = '';
  if (isDeletedProp) {
    label = 'deleted';
  } else if (statusProp) {
    label = statusProp;
  }

  return <IntlMessages id={label} />;
};

export const getIntervalLabel = data => {
  const intervalProp = data.props ? data.props.interval : data.interval;

  return <IntlMessages id={intervalProp} />;
};

export const getScheduleTypeLabel = data => {
  const scheduleTypeProp = data.props
    ? data.props.scheduleType
    : data.scheduleType;

  return <IntlMessages id={scheduleTypeProp} />;
};

export const shouldHideMenuItem = ({
  item,
  isSuperAdmin,
  currentOrganization,
}) => {
  const orglessMenuItems = [
    'branches',
    'admins',
    'purchases',
    'aggregateTransactions',
  ];
  const superAdminMenuItems = ['dashboards', ...orglessMenuItems];

  const checkAggregatedView =
    isSuperAdmin &&
    (!currentOrganization || !currentOrganization.id) &&
    !superAdminMenuItems.includes(item.id);

  const checkOrgView =
    currentOrganization &&
    currentOrganization.id &&
    orglessMenuItems.includes(item.id);

  return checkAggregatedView || checkOrgView;
};

export const isMenuItemEnabled = (userPermissions, item) => {
  switch (item.to) {
    case PATH.ENTRIES.DRIVER:
      return userPermissions.some(
        permission => permission === PERMISSIONS.DRIVER.LIST
      );
    case PATH.ENTRIES.CONTAINER:
      return userPermissions.some(
        permission => permission === PERMISSIONS.CONTAINER.LIST
      );
    case PATH.ENTRIES.RECIPIENT:
      return userPermissions.some(
        permission => permission === PERMISSIONS.RECIPIENT.LIST
      );
    case PATH.ENTRIES.VEHICLE:
      return userPermissions.some(
        permission => permission === PERMISSIONS.VEHICLE.LIST
      );
    case PATH.BALANCE.DISTRIBUTION:
      return userPermissions.some(
        permission => permission === PERMISSIONS.BALANCE_DISTRIBUTION.LIST
      );
    case PATH.SECURITY.USERS:
      return userPermissions.some(
        permission => permission === PERMISSIONS.USER_PERMISSIONS.LIST
      );
    case PATH.SYSTEM.SETTINGS:
      return userPermissions.some(
        permission => permission === PERMISSIONS.SETTINGS.LIST
      );
    case PATH.TRANSACTIONS:
      return userPermissions.some(
        permission => permission === PERMISSIONS.TRANSACTIONS.LIST
      );
    case PATH.DEPOSITS:
      return userPermissions.some(
        permission => permission === PERMISSIONS.DEPOSITS.LIST
      );
    case PATH.BILLS:
      return userPermissions.some(
        permission => permission === PERMISSIONS.BILLS.LIST
      );
    case PATH.REPORTS.BALANCE_SHARING:
      return userPermissions.some(
        permission => permission === PERMISSIONS.BALANCE_SHARING.LIST
      );
    case PATH.STATIONS:
      return userPermissions.some(
        permission => permission === PERMISSIONS.GAS_STATIONS.LIST
      );
    case PATH.ADMIN:
      return userPermissions.some(
        permission => permission === PERMISSIONS.ADMIN.LIST
      );
    case PATH.BRANCHES:
      return userPermissions.some(
        permission => permission === PERMISSIONS.BRANCHES.LIST
      );
    case PATH.INTERNAL_TANK.TANKS:
      return userPermissions.some(
        permission => permission === PERMISSIONS.INTERNAL_TANK.TANKS.LIST
      );
    case PATH.INTERNAL_TANK.BALANCE:
      return userPermissions.some(
        permission => permission === PERMISSIONS.INTERNAL_TANK.BALANCE.LIST
      );
    default:
      return true;
  }
};

export const getIdFromVehicles = ({ list, value }) => {
  const textWithoutHyphen = text => text.replace(/[-]/g, '');

  return Array.from(
    new Set(
      list
        .map(item => {
          if (
            item._vehicle &&
            textWithoutHyphen(item._vehicle.licensePlate)
              .toLowerCase()
              .includes(textWithoutHyphen(value).toLowerCase())
          ) {
            return item._vehicle._id;
          }
          return null;
        })
        .filter(Boolean)
    )
  );
};

export const normalizeText = text =>
  text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

export const getIdsFromEntity = ({ list, key, term, value }) =>
  Array.from(
    new Set(
      list
        .map(item => {
          if (
            item[key] &&
            normalizeText(item[key][term]).includes(normalizeText(value))
          ) {
            return item[key]._id;
          }
          return null;
        })
        .filter(Boolean)
    )
  );

export const getUserFromEntity = ({ list, key, term, value }) =>
  Array.from(
    new Set(
      list
        .map(item => {
          if (
            item[key] &&
            normalizeText(item[key][term]).includes(normalizeText(value))
          ) {
            return item[key]._user;
          }
          return null;
        })
        .filter(Boolean)
    )
  );

export const isMobile = () => {
  const width = window.innerWidth;
  return width < 425;
};

export const sanitizePassword = value => value.replace(/[\D]/g, '').slice(0, 4);

export const validatePassword = {
  minLength: value => value.length >= 8,
  lowerAndUpperCase: value => /(?=.*[a-z])(?=.*[A-Z])/.test(value),
  alphanumeric: value => /(?=.*[a-z])(?=.*[0-9])/i.test(value),
};

export const formatPostalCode = postalCode => {
  return `${String(postalCode).slice(0, 5)}-${String(postalCode).slice(5, 8)}`;
};
