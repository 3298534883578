import api from './api';

export const list = (params, payload) => {
  return api.post(`/superAdmin/organization/transaction`, payload, {
    params,
  });
};

export const listTankTransactions = (params, payload) =>
  api.post(`/superAdmin/organization/tank/transaction`, payload, {
    params,
  });
