import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/organization/expense-center${objectToURLParams(parameters)}`);

export const create = (data, organizationId) =>
  api.post(
    `/admin/organization/expense-center?organizationId=${organizationId}`,
    data
  );

export const edit = (id, data, organizationId) =>
  api.put(
    `/admin/organization/expense-center/${id}?organizationId=${organizationId}`,
    data
  );

export const remove = (id, organizationId) =>
  api.delete(
    `/admin/organization/expense-center/${id}?organizationId=${organizationId}`
  );

export const undelete = (id, organizationId) =>
  api.put(`/expense-center/recover/${id}?organizationId=${organizationId}`);

export const listAggregate = (params, payload) =>
  api.post(`/superAdmin/organization/expenseCenter`, payload, { params });
