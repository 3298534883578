import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';

import { getCurrentOrganization } from 'redux/organizations/selectors';
import {
  listExpenseCentersRequest,
  listExpenseCentersSuccess,
  listExpenseCentersError,
  createExpenseCenterSuccess,
  createExpenseCenterError,
  updateExpenseCenterSuccess,
  updateExpenseCenterError,
  deleteExpenseCenterSuccess,
  deleteExpenseCenterError,
  EXPENSE_CENTER_LIST_REQUEST,
  EXPENSE_CENTER_CREATE_REQUEST,
  EXPENSE_CENTER_UPDATE_REQUEST,
  EXPENSE_CENTER_DELETE_REQUEST,
  undeleteExpenseCenterError,
  undeleteExpenseCenterSuccess,
  EXPENSE_CENTER_UNDELETE_REQUEST,
  listAggregateExpenseCentersSuccess,
  listAggregateExpenseCentersError,
  AGGREGATE_EXPENSE_CENTER_LIST_REQUEST,
} from './actions';

function* listExpenseCenters(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);

  const response = yield call(
    api.list,
    APIParams({
      ...payload.params,
      organizationId: currentOrganization.id,
    })
  );

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listExpenseCentersSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(listExpenseCentersError(message));
  }
}

function* createExpenseCenter(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);

  const response = yield call(api.create, payload.body, currentOrganization.id);

  if (response.ok) {
    yield put(createExpenseCenterSuccess());
  } else {
    const expenseCenterAlreadyExists = response.data.statusCode === 409;

    const message = expenseCenterAlreadyExists
      ? `Não foi possível criar o centro de custo ${payload.body.name}. Verifique se já existe um registro com este mesmo nome.`
      : 'Não foi possível criar o centro de custo no momento. Por favor, tente novamente mais tarde.';

    yield put(createExpenseCenterError(message));
  }
  yield put(listExpenseCentersRequest(payload.params));
}

function* updateExpenseCenter(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);

  const response = yield call(
    api.edit,
    payload.id,
    payload.body,
    currentOrganization.id
  );

  if (response.ok) {
    yield put(updateExpenseCenterSuccess());
  } else {
    const expenseCenterAlreadyExists = response.data.statusCode === 409;
    const message = expenseCenterAlreadyExists
      ? `Não foi possível atualizar o centro de custo ${payload.body.name}. Verifique se já existe um registro com este mesmo nome.`
      : 'Não foi possível atualizar este centro de custo no momento. Por favor, tente novamente mais tarde.';

    yield put(updateExpenseCenterError(message));
  }
  yield put(listExpenseCentersRequest(payload.params));
}

function* deleteExpenseCenter(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);

  const response = yield call(api.remove, payload.id, currentOrganization.id);

  if (response.ok) {
    yield put(deleteExpenseCenterSuccess());
    yield put(listExpenseCentersRequest(payload.params));
  } else {
    const message = 'Não foi possível deletar este centro de custo';

    yield put(deleteExpenseCenterError(message));
  }
}

function* undeleteExpenseCenter(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(api.undelete, payload.id, currentOrganization.id);

  if (response.ok) {
    yield put(undeleteExpenseCenterSuccess());
    yield put(listExpenseCentersRequest(payload.params));
  } else {
    const message = 'Não foi possível recuperar o centro de custo';
    yield put(undeleteExpenseCenterError(message));
  }
}

function* listAggregateExpenseCenters(api, { payload }) {
  const response = yield call(
    api.listAggregate,
    APIParams(payload.params),
    payload.body
  );

  if (response.ok) {
    const list = response.data.docs;

    yield put(listAggregateExpenseCentersSuccess({ list }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(listAggregateExpenseCentersError(message));
  }
}

export function* watchListExpenseCenters(api) {
  yield takeEvery(EXPENSE_CENTER_LIST_REQUEST, listExpenseCenters, api);
}

export function* watchCreateExpenseCenter(api) {
  yield takeEvery(EXPENSE_CENTER_CREATE_REQUEST, createExpenseCenter, api);
}

export function* watchUpdateExpenseCenter(api) {
  yield takeEvery(EXPENSE_CENTER_UPDATE_REQUEST, updateExpenseCenter, api);
}

export function* watchDeleteExpenseCenter(api) {
  yield takeEvery(EXPENSE_CENTER_DELETE_REQUEST, deleteExpenseCenter, api);
}

export function* watchUndeleteExpenseCenter(api) {
  yield takeEvery(EXPENSE_CENTER_UNDELETE_REQUEST, undeleteExpenseCenter, api);
}

export function* watchListAggregateExpenseCenters(api) {
  yield takeEvery(
    AGGREGATE_EXPENSE_CENTER_LIST_REQUEST,
    listAggregateExpenseCenters,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListExpenseCenters, api),
    fork(watchCreateExpenseCenter, api),
    fork(watchUpdateExpenseCenter, api),
    fork(watchDeleteExpenseCenter, api),
    fork(watchUndeleteExpenseCenter, api),
    fork(watchListAggregateExpenseCenters, api),
  ]);
}
