export const AGGREGATE_TRANSACTION_LIST_REQUEST =
  'AGGREGATE_TRANSACTION_LIST_REQUEST';
export const AGGREGATE_TRANSACTION_LIST_SUCCESS =
  'AGGREGATE_TRANSACTION_LIST_SUCCESS';
export const AGGREGATE_TRANSACTION_LIST_ERROR =
  'AGGREGATE_TRANSACTION_LIST_ERROR';

export const listAggregateTransactions = (params, body) => ({
  type: AGGREGATE_TRANSACTION_LIST_REQUEST,
  payload: { params, body },
});

export const listAggregateTransactionsSuccess = payload => ({
  type: AGGREGATE_TRANSACTION_LIST_SUCCESS,
  payload,
});

export const listAggregateTransactionsError = message => ({
  type: AGGREGATE_TRANSACTION_LIST_ERROR,
  payload: { message },
});
