import * as auth from './auth';
import * as driver from './driver';
import * as recipient from './recipient';
import * as transactions from './transactions';
import * as vehicle from './vehicle';
import * as container from './container';
import * as organizations from './organizations';
import * as admin from './admin';
import * as report from './report';
import * as bills from './bills';
import * as stations from './stations';
import * as deposits from './deposits';
import * as balance from './balance';
import * as hydrate from './hydrate';
import * as settings from './settings';
import * as user from './user';
import * as permissions from './permissions';
import * as userPermissions from './userPermission';
import * as subscription from './subscription';
import * as fuelManagement from './fuelManagement';
import * as purchase from './purchase';
import * as expenseCenter from './expenseCenter';
import * as expenseCenterBalance from './expenseCenterBalance';
import * as anywhere from './anywhere';
import * as routeScript from './routeScript';
import * as aggregateTransactions from './aggregateTransactions';

export default {
  auth,
  driver,
  recipient,
  transactions,
  vehicle,
  container,
  organizations,
  admin,
  report,
  bills,
  stations,
  deposits,
  balance,
  hydrate,
  settings,
  user,
  permissions,
  userPermissions,
  subscription,
  fuelManagement,
  purchase,
  expenseCenter,
  expenseCenterBalance,
  anywhere,
  routeScript,
  aggregateTransactions,
};
