import produce from 'immer';

import {
  EXPENSE_CENTER_LIST_REQUEST,
  EXPENSE_CENTER_LIST_SUCCESS,
  EXPENSE_CENTER_LIST_ERROR,
  EXPENSE_CENTER_CREATE_REQUEST,
  EXPENSE_CENTER_CREATE_SUCCESS,
  EXPENSE_CENTER_CREATE_ERROR,
  EXPENSE_CENTER_UPDATE_REQUEST,
  EXPENSE_CENTER_UPDATE_SUCCESS,
  EXPENSE_CENTER_UPDATE_ERROR,
  EXPENSE_CENTER_DELETE_REQUEST,
  EXPENSE_CENTER_DELETE_SUCCESS,
  EXPENSE_CENTER_DELETE_ERROR,
  EXPENSE_CENTER_RESET_STATE,
  EXPENSE_CENTER_UNDELETE_REQUEST,
  EXPENSE_CENTER_UNDELETE_ERROR,
  EXPENSE_CENTER_UNDELETE_SUCCESS,
  AGGREGATE_EXPENSE_CENTER_LIST_REQUEST,
  AGGREGATE_EXPENSE_CENTER_LIST_SUCCESS,
  AGGREGATE_EXPENSE_CENTER_LIST_ERROR,
} from './actions';

const INITIAL_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
  success: false,
  create: {
    loading: false,
    error: '',
    success: false,
  },
  update: {
    loading: false,
    error: '',
    success: false,
  },
  remove: {
    loading: false,
    error: '',
    success: false,
  },
  undelete: {
    loading: false,
    error: '',
    success: false,
  },
  aggregate: {
    list: [],
    loading: false,
    error: '',
    success: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case EXPENSE_CENTER_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case EXPENSE_CENTER_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }
      case EXPENSE_CENTER_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }
      case EXPENSE_CENTER_CREATE_REQUEST: {
        draft.create.loading = true;
        draft.create.error = '';
        break;
      }
      case EXPENSE_CENTER_CREATE_SUCCESS: {
        draft.create.loading = false;
        draft.create.error = '';
        draft.create.success = true;
        break;
      }
      case EXPENSE_CENTER_CREATE_ERROR: {
        draft.create.loading = false;
        draft.create.error = action.payload.message;
        draft.create.success = false;
        break;
      }
      case EXPENSE_CENTER_UPDATE_REQUEST: {
        draft.update.loading = true;
        draft.update.error = '';
        break;
      }
      case EXPENSE_CENTER_UPDATE_SUCCESS: {
        draft.update.loading = false;
        draft.update.error = '';
        draft.update.success = true;
        break;
      }
      case EXPENSE_CENTER_UPDATE_ERROR: {
        draft.update.loading = false;
        draft.update.error = action.payload.message;
        draft.update.success = false;
        break;
      }
      case EXPENSE_CENTER_DELETE_REQUEST: {
        draft.remove.loading = true;
        draft.remove.error = '';
        break;
      }
      case EXPENSE_CENTER_DELETE_SUCCESS: {
        draft.remove.success = true;
        draft.remove.loading = false;
        draft.remove.error = '';
        break;
      }
      case EXPENSE_CENTER_DELETE_ERROR: {
        draft.remove.loading = false;
        draft.remove.error = action.payload.message;
        draft.remove.success = false;
        break;
      }
      case EXPENSE_CENTER_RESET_STATE: {
        draft.create.loading = false;
        draft.create.error = '';
        draft.create.success = false;
        draft.update.loading = false;
        draft.update.error = '';
        draft.update.success = false;
        draft.remove.loading = false;
        draft.remove.error = '';
        draft.remove.success = false;
        draft.undelete.loading = false;
        draft.undelete.error = '';
        draft.undelete.success = false;
        break;
      }
      case EXPENSE_CENTER_UNDELETE_REQUEST: {
        draft.undelete.loading = true;
        draft.undelete.error = '';
        break;
      }
      case EXPENSE_CENTER_UNDELETE_SUCCESS: {
        draft.undelete.success = true;
        draft.undelete.loading = false;
        draft.undelete.error = '';
        break;
      }
      case EXPENSE_CENTER_UNDELETE_ERROR: {
        draft.undelete.loading = false;
        draft.undelete.error = action.payload.message;
        draft.undelete.success = false;
        break;
      }

      case AGGREGATE_EXPENSE_CENTER_LIST_REQUEST: {
        draft.aggregate.loading = true;
        draft.aggregate.error = '';
        break;
      }
      case AGGREGATE_EXPENSE_CENTER_LIST_SUCCESS: {
        draft.loading = false;
        draft.aggregate.list = action.payload.list;
        draft.aggregate.error = '';
        break;
      }
      case AGGREGATE_EXPENSE_CENTER_LIST_ERROR: {
        draft.aggregate.loading = false;
        draft.aggregate.list = [];
        draft.aggregate.error = action.payload.message;
        break;
      }

      default:
        return state;
    }
  });
};
