import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import { LITROZ_TRANSACTION } from 'constants/defaultValues';
import { AGGREGATE_TRANSACTION_LIST_REQUEST } from '../actions';

import {
  listAggregateTransactionsSuccess,
  listAggregateTransactionsError,
} from './actions';

function* getAggregateTransactions(api, { payload }) {
  const { type, ...params } = payload.params;

  const transactions = yield call(
    type === LITROZ_TRANSACTION ? api.list : api.listTankTransactions,
    APIParams(params),
    payload.body
  );

  if (transactions.ok) {
    const list = transactions.data.docs;
    const { current: currentPage, total: totalPages } = transactions.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = transactions.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listAggregateTransactionsSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listAggregateTransactionsError(message));
  }
}

export function* watchListAggregateTransactions(api) {
  yield takeEvery(
    AGGREGATE_TRANSACTION_LIST_REQUEST,
    getAggregateTransactions,
    api
  );
}

export default function* rootSaga(api) {
  yield all([fork(watchListAggregateTransactions, api)]);
}
